import Welcome from '../Welcome';
import Account from '../Welcome/Account';
import Activate from '../Welcome/Activate';
import AddPayment from '../Welcome/AddPayment';
import Finish from '../Welcome/Finish';
import General from '../Welcome/General';
import Plans from '../Welcome/Plans';
import Questionnaire from '../Welcome/Questionnaire';
// import Schedule from '../Welcome/Schedule';
import Setup from '../Welcome/Setup';
import SignUp from '../Welcome/SignUp';
import ThankYou from '../Welcome/ThankYou';
import Therapists from '../Welcome/Therapists';
import Verify from '../Welcome/Verify';
import Help from '../Welcome/Help';
// Therapist profile temp view
import TherapistProfile from '../Welcome/TherapistProfile';

import SignIn from '../Welcome/Signin';
import InviteSignup from '../Welcome/InviteSignup';

export default [
  { path: '/welcome', component:  Welcome },
  { path: '/welcome/questionnaire', component: Questionnaire },
  { path: '/welcome/thankyou', component: ThankYou },
  { path: '/welcome/account', component: Account },
  { path: '/welcome/signup', component: SignUp },
  { path: '/welcome/start', component: Finish, isPrivate: true },
  { path: '/welcome/general', component: General, isPrivate: true },
  { path: '/welcome/plans', component: Plans, isPrivate: true },
  // { path: '/welcome/activate', component: Activate, isPrivate: true },
  { path: '/welcome/setup', component: Setup, isPrivate: true },
  { path: '/welcome/therapists', component: Therapists, isPrivate: true },
  // { path: '/welcome/schedule', component: Schedule, isPrivate: true },
  { path: '/welcome/addpayment', component: AddPayment, isPrivate: true },
  { path: '/welcome/verify', component: Verify },
  { path: '/welcome/signin', component: SignIn },
  { path: '/help', component: Help },
  // This is the B2B registration
  { path: '/welcome/:userType/signup', component: SignUp },
  { path: '/welcome/e/invite', component: InviteSignup },
  /**
   *      The SMB registration flow and the B2C Registration flow
   *      Here
   *            c => is for B2C & SMB
   *            e => for B2B
   *      Previously we used e => as enterprise / B2B users
   */
  { path: '/welcome/activate', component: Activate, isPrivate: true },
  { path: '/welcome/signin', component: Activate, isPrivate: true },
  { path: '/welcome/:userType/questionnaire', component: Questionnaire },
  { path: '/welcome/:userType?/thankyou', component: ThankYou },
  { path: '/welcome/:userType?/account', component: Account },
  { path: '/welcome/:userType?/signup', component: SignUp },
  { path: '/welcome/therapist-profile/:userId', component: TherapistProfile },
];